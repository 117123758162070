import {
    Component,
    ViewChild,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    OnDestroy,
    AfterViewInit,
    Input
  } from '@angular/core';
  import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
  import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TabService } from 'src/app/services/tab.service';
import { UniversalSessionService } from 'src/app/services/universalSession.service';
  
  @Component({
    selector: 'app-window',
    template: `<ng-container *cdkPortal ></ng-container>` // remove text after Saml integration
  })
  export class WindowComponent implements AfterViewInit, OnDestroy {
  
    private externalWindow: Window | null = null;
    subscription!: Subscription;
    intervalId!: number;
    private alive = environment.univ_alive;
    private tabAliveInterval = this.alive.tabAliveInterval || 15000;  // defaults to .25 minute
    public tabName: string = '';
  
    @Input() appName: any;
    @Input() SamlUrl: any;
   // @Input() SamlInfo;
  
    @ViewChild(CdkPortal) portal!: CdkPortal;
    constructor( private tabService: TabService,
                 private componentFactoryResolver: ComponentFactoryResolver,
                 private sessionService: UniversalSessionService,
                 private applicationRef: ApplicationRef,
                 private injector: Injector){}
  
    ngAfterViewInit(){
      const tabName = this.appName;
      this.tabName = tabName;  //  remove after Saml integration
      this.externalWindow = window.open('', tabName );
      this.tabService.saveAppTab(this.appName, this.externalWindow);
      this.externalWindow?.focus();
      const body = this.externalWindow?.document.body
  
      if (body) {
        const host = new DomPortalOutlet(
            body,
            this.componentFactoryResolver,
            this.applicationRef,
            this.injector);
        host.attach(this.portal);
      }

  
    //  this.createSamlForm();
  
      this.externalWindow?.open(this.SamlUrl, tabName);
  
      const source = interval(this.tabAliveInterval);
      this.subscription = source.subscribe(val => this.keepAlive(tabName));
    }
  
    keepAlive(tabName: any) {
      if (!this.externalWindow?.closed) {
        this.sessionService.extendWebSession().subscribe((res: any) => {
          console.warn('>>>> web session ended!');
        });
      } else {
        this.endAll();
      }
    }
  
    /**
     * Currently not being used for SAML - wait until OIDC to determine deletion
     * Creates the saml post form
     * currently not using saml post with pingFederate as a identity provider
     */
    createSamlForm() {
      // TODO: needs more work!!
      // and probably needs to move into a service if it works correctly
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = this.SamlUrl;
      form.target = this.appName;
      form.style.display = 'none';
  
      // const test = this.SamlInfo;
  
      // for (const key in data) {
      //   if (data.hasOwnProperty(key)) {
      //     const input = document.createElement('input');
      //     input.type = 'hidden';
      //     input.name = key;
      //     input.value = data[key];
      //     form.appendChild(input);
      //   }
      // }
      document.body.appendChild(form);
      //  form.submit();  // TODO uncomment when SAML is integrated
    }
  
    endAll() {
      console.warn('>>core2 WINDOW endall');
      this.subscription.unsubscribe(); // stop keep alive
    }
  
    ngOnDestroy(){
      this.endAll();
    }
  }
  