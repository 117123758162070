import { Injectable, ViewContainerRef } from '@angular/core';
import { from, Observable } from 'rxjs';
import { sharedState } from '@citizens/mfe-shared-state';
import { TransmitUiHandler } from './transmit-ui-handler';
import { ApplicationService } from './app.service';
import { SsoService } from './application.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderTransmitService {
  private readonly TRANSMIT_UI_CONTAINER_ID = 'appContent';
  private viewContainerRef: ViewContainerRef | undefined;
  private loginComp: any;
  public transmitSDK!: com.ts.mobile.sdk.TransmitSDKXm;

  constructor(private ssoUiHandler: TransmitUiHandler,
    private appService: SsoService
  ) {
    sharedState.getApplicationDataSub('transmitInstance').subscribe(data => {
      console.log(data);
      this.transmitSDK = data.sdk;
    });
  }

initializeTransmit(transmitUrl: string): Observable<boolean> {

  const appName = 'web_universal_portal';
  const tokenName = undefined;
  const token = undefined;
  // return com.ts.mobile.sdk.SDKConnectionSettings.create(serverUrl, appName, tokenName, token );

  const settings = this.getTransmitConnectionSettings(transmitUrl, appName);
  this.transmitSDK.setConnectionSettings(settings);

  this.transmitSDK.setUiHandler(this.ssoUiHandler as any); // Using 'any' type due to type definition limitations
  return from(
    this.transmitSDK.initialize().then((result: any) => {        
      return result;
    })
  );
}

getTransmitConnectionSettings(serverUrl: any, applicationName: any) {
  const appName = applicationName;
  const tokenName = '';
  const token = '';
  return com.ts.mobile.sdk.SDKConnectionSettings.create(serverUrl, appName, tokenName, token);
}

authenticate(universalId: any, profilingDetails: any, journeyName: any, tmxUnivSessionId: any, profiledUrl: any): Promise<any> {

  return this.login(universalId, profilingDetails ,journeyName, tmxUnivSessionId, profiledUrl);
}

async login(universalId: any, profilingDetails: any, journeyName: any, tmxUnivSessionId: any, profiledUrl: any): Promise<com.ts.mobile.sdk.AuthenticationResult> {

  const clientContext = {
    // uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
    // viewContainerRef: this.viewContainerRef,
    // loginComp: this.loginComp,
    // passtext: passtext
  };
  const additionalParams = {
    'universal_id': universalId,
    'profiling_details': profilingDetails,
    'tm_session_id': tmxUnivSessionId,
    'profile_Url': profiledUrl
  };

  
  return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
}

pingDropOffTransmitInvokePolicy(userId: string, compId: string, appName: any, webAppId: string, legacyId: string, journeyName: string | null, universalId: any, legacyEmailId: any, preProdValue: string | null, nonOUDAppDetails: any) {
    const clientContext = {
    //   uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
    //   viewContainerRef: this.viewContainerRef,
    //   loginComp: this.loginComp
    };
    const additionalParams: {[key: string]: string | null} = {};
    if (this.appService.getNonOUDAppsList().includes(appName)) {
      additionalParams['companyID'] = compId || '';
      additionalParams['appId'] = webAppId || '';
      additionalParams['userId'] = userId || '';
      additionalParams['legacy_uid'] = legacyId || '';
      // include DACA changes
      if (this.appService.getConfigAppsData(appName)?.usesTargetUrl) {
        const url = this.appService.getConfigAppsData(appName)?.baseUrls?.sfTarget;
        additionalParams['sfTargetPath'] = url || '';
      }
    } else {
      additionalParams['legacy_uid'] = legacyId || '';
    }

    if (environment.isSIT) {
      additionalParams['sso_env'] = 'sit';
    }
    //Preprod Environment setup
    if (environment.isQA && sessionStorage.getItem('preprod')) {
      additionalParams['sso_env'] = preProdValue;
    }
    return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
  }
}
