import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, retry } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ApplicationService {
    public updateLastLoginDetailsUrl = environment.univ_updateLastLoginDetails;
    constructor(private https: HttpClient) {}

    getNotificationsData(data: any): Observable<any> {
        const url = environment.notificationsUrl;
        return this.https.get(url, { headers: data });
    }

    updateLastLogin(accountLoginId: any, universalId: string) {
        const url = this.updateLastLoginDetailsUrl + universalId;
    
        const body = {
          "accountLogin": accountLoginId,
          "lastLoginDt": ""
        };
        this.https.post<any>(url, body).subscribe(res => {
          console.log(res);
        });
    
      }

      // config
  getAppsData(): Observable<any[]> {
    const url = environment.univ_apps_url;
    return this.https.get<any[]>(url);
  }

}